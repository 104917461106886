import axios from "axios";

const username = 'HubAuthUser';
const password = 'HubAuth@01!!';
const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

export default axios.create({
  headers: {
    //"Content-type": "application/json",
    'Authorization': `Basic ${token}`
  }
});