import React from "react";

export const MaintenanceAnalysisConfig = {
  settings: {
    layout: {
          config: {
              rightSidePanel: {
                  display: false
              }
          }
    }
  },
  routes: [
    {
          path: "/analytics/maintenance-analysis",
          component: React.lazy(() => import("./MaintenanceAnalysis"))
    }
  ]
};
