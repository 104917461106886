import React from "react";

export const MaintenancePlansConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/maintenance-management/maintenance-plans/:productId",
      component: React.lazy(() => import("./MaintenanceRecord"))
    },
    {
      path: "/maintenance-management/maintenance-plans",
      component: React.lazy(() => import("./MaintenancePlans"))
    },
  ]
};
