import React from 'react';
import {Icon, IconButton} from '@material-ui/core';
import {useDispatch} from 'react-redux';

function HelpButton()
{
    const dispatch = useDispatch();

    function handleClick(e) {
        e.preventDefault();
        window.open("https://ftsinternational.eamstechnologies.com/portal/home");
    }

    return (
        <IconButton className="w-64 h-64" onClick={handleClick}>
            <Icon>helpoutline</Icon>
        </IconButton>
    );
}

export default HelpButton;
