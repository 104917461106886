import React from "react";

export const WorkRequestsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/maintenance-management/field-failure-report",
      component: React.lazy(() => import("./WorkRequests")),
    },
  ],
};
