import React from "react";
import {
  AppBar,
  Hidden,
  Icon,
  Toolbar,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { red } from "@material-ui/core/colors";
import { FuseSearch, FuseShortcuts } from "../../../../@fuse";
import NavbarMobileToggleButton from "../../../../app/fuse-layouts/shared-components/NavbarMobileToggleButton";
import QuickPanelToggleButton from "../../../../app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton";
import UserMenu from "../../../../app/fuse-layouts/shared-components/UserMenu";
import HelpButton from "../../../../app/fuse-layouts/shared-components/HelpButton";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider,
  },
}));

function ToolbarLayout1(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);
  const environment = useSelector(({ auth }) => auth.user.environment);
  let title = "PROFRAC – UAT";

  const classes = useStyles(props);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar id="fuse-toolbar" className="flex relative z-10" color="default">
        <Toolbar className="p-0">
          {config.navbar.display && config.navbar.position === "left" && (
            <Hidden lgUp>
              <NavbarMobileToggleButton className="w-64 h-64 p-0" />
              <div className={classes.separator} />
            </Hidden>
          )}

          <div className="flex flex-1">
            {/*<Hidden mdDown>
              <FuseShortcuts className="px-16" />
            </Hidden>*/}
          </div>

          <h2 className="flex flex-1">
            <b>{title}</b>
          </h2>

          <div className="flex">
            <UserMenu />
            <div className={classes.separator} />
            <HelpButton />
            {/* <IconButton className="w-64 h-64">
                          <Icon>helpoutline</Icon>
                      </IconButton> */}

            <div className={classes.separator} />

            <FuseSearch />

            <Hidden lgUp>
              <div className={classes.separator} />
            </Hidden>

            <div className={classes.separator} />

            <QuickPanelToggleButton />
            {/*<Button id="fuse-settings" variant="contained" onClick={props.handleOpen}>
                    <Icon>settings</Icon>
                </Button>*/}
          </div>

          {config.navbar.display && config.navbar.position === "right" && (
            <Hidden lgUp>
              <NavbarMobileToggleButton />
            </Hidden>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default ToolbarLayout1;
