import React from "react";

export const DOTComplianceConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: "/analytics/dot-compliance",
            component: React.lazy(() => import("./DOTCompliance"))
        }
    ]
};