import IotManagement from "../main/Icons/iot-management.png";
import IotHealth from "../main/Icons/iot-health.png";
import Locations from "../main/Icons/locations.png";
import Networks from "../main/Icons/network.png";
import Gateways from "../main/Icons/gateway.png";
import Sensors from "../main/Icons/sensor.png";
import ActivityManagement from "../main/Icons/activity-management.png";
import KanbanIcon from "../main/Icons/kanban.png";
import MaintenanceSystem from "../main/Icons/fracttal.png";
import Analytics from "../main/Icons/analytics.png";
import Devices from "../main/Icons/devices.png";
import Maintenance from "../main/Icons/maintenance-analysis-100-white.png";
import MaintenanceManagement from "../main/Icons/maintenance-management-64-white.png";
import Customs from "../main/Icons/customs.png";
import Equipment from "../main/Icons/equipment-analysis-100-white.png";
import AssetManagementIcon from "../main/Icons/asset-management-100-white.png";
import SystemAnalysis from "../main/Icons/system-status-100-white.png";
import StockAnalysis from "../main/Icons/stock-analysis-100-white.png";
import DocumentManagement from "../main/Icons/document-management-100-white.png";
import ClassificationSchemes from "../main/Icons/classification-schemes-100-white.png";
import Crews from "../main/Icons/crews-100-white.png";
import FacilitiesIcon from "../main/Icons/facilities-icon.png";
import AssetsIcon from "../main/Icons/Assets-Icon.png";
import LifeCycleManagementIcon from "../main/Icons/Life-Cycle-Management.png";
import HSSEIcon from "../main/Icons/HSSE-Icon.png";
import RiskManagementIcon from "../main/Icons/Risk-Management-Icon.png";
import ContractorManagementIcon from "../main/Icons/Contractor-Management-Icon.png";
import ChangeManagementIcon from "../main/Icons/change-management.png";
import StrategyManagementIcon from "../main/Icons/strategy-management.png";
import DocumentsManagementIcon from "../main/Icons/documents-management.png";
import ProjectsIcon from "../main/Icons/projects.png";
import TeamCalendarIcon from "../main/Icons/team-calendar.png";
import CollaborationIcon from "../main/Icons/collaboration.png";
import PeopleIcon from "../main/Icons/people.png";
import MaterialsManagement from "../main/Icons/materials-management.png";
import PartsCatalogueIcon from "../main/Icons/parts-catalogue.png";
import StorageLocationsIcon from "../main/Icons/storage-locations.png";
import SuppliesIcon from "../main/Icons/suppliers-icon.png";
import OrderTrackingIcon from "../main/Icons/order-tracking.png";
import PurchaseRequestsIcon from "../main/Icons/purchase-requests.png";
import SettingsIcon from "../main/Icons/settings.png";
import UserCheckIcon from "../main/Icons/person-confirm.png";
import UserKeyIcon from "../main/Icons/person-key.png";
import StaffIcon from "../main/Icons/person-staff.png";
import PeopleGroupIcon from "../main/Icons/people-group.png";
import navAuthProfiles from '../auth/navAuthProfiles';

const navigationConfig = [

    {
        id: "analytics",
        title: "Analytics",
        type: "collapse",
        icon: Analytics,
        access: navAuthProfiles.analyticsOnly,
        children: [
            {
                id: "maintenance-analysis",
                title: "Maintenance Analysis",
                type: "item",
                icon: Maintenance,
                access: navAuthProfiles.analyticsOnly,
                url: "/analytics/maintenance-analysis"
            },
            {
                id: "equipment-analysis",
                title: "Equipment Analysis",
                type: "item",
                icon: Equipment,
                access: navAuthProfiles.analyticsOnly,
                url: "/analytics/equipment-analysis"
            }
        ]
    },
    {
        id: "maintenance-management",
        title: "Maintenance Management",
        type: "collapse",
        icon: MaintenanceManagement,
        access: navAuthProfiles.technician,
        children: [
            {
                id: "work-order-kanban",
                title: "Work Orders",
                type: "item",
                icon: KanbanIcon,
                access: navAuthProfiles.technician,
                url: "/maintenance-management/work-order-board/boards/1/work-order-kanban"
            },
            {
                id: "work-requests",
                title: "Work Requests",
                type: "item",
                icon: AssetsIcon,
                access: navAuthProfiles.technician,
                url: "/maintenance-management/work-request"
            },
            {
                id: "people",
                title: "Personnel",
                type: "item",
                icon: StaffIcon,
                access: navAuthProfiles.admin,
                url: "/maintenance-management/personnel"
            },
            {
                id: "crews",
                title: "Fleets",
                type: "item",
                icon: Crews,
                access: navAuthProfiles.moveManager,
                url: "/maintenance-management/crew"
            },
        ]
    },
    {
        id: "settings",
        title: "Settings",
        type: "collapse",
        icon: SettingsIcon,
        access: navAuthProfiles.admin,
        children: [
            {
                id: "users",
                title: "Users",
                type: "item",
                icon: PeopleIcon,
                access: navAuthProfiles.admin,
                url: "/settings/user"
            }

        ]
    }
];

export default navigationConfig;