import httprequest from "./httprequest";

// getters

const getAllAssets = () => {
    return httprequest.get("/api/Live/MobileAssets/?fullList=true");
};

const getAssetFromCode = (code) => {
    return httprequest.get("/api/Live/MobileAssets/" + code);
};
const getAssetFromCodeMain = (code) => {
  return httprequest.get("/api/Live/Assets/Main/"+code);
};

const getAllAssetLogs = (code) => {
  return httprequest.get("/api/Live/AssetLogs/?assetCode="+code);
};

const getAllAssetRecentActivityLogs = (code) => {
  return httprequest.get("/api/Live/AssetLogs/RecentActivities/?assetCode="+code);
};

const getAllUsers = () => {
    return httprequest.get("/api/Live/MobileUser/?active=true");
};

const getLabourUsers = (plant) => {
    return httprequest.get("/api/Live/MobileUser/?plant=" + plant);
};

const getLeads = () => {
    return httprequest.get("/api/Live/MobileUser/?isLead=true");
};

const getAllPersonnelWithPlants = () => {
    return httprequest.get("/api/Live/Personnel/?withPlants=true");
};

const getAllPersonnel = () => {
    return httprequest.get("/api/Live/Personnel/");
};

const getAllPersonnelCrew = (crewId) => {
  return httprequest.get("/api/Live/Personnel/?CrewId="+crewId);
};

const getNonUserPersonnel = () => {
    return httprequest.get("/api/Live/Personnel/?nonUsers=true");
};

const getAllSettingsUsers = () => {
    return httprequest.get("/api/Live/User/?userDetails=true");
};

const getUserLimits = () => {
    return httprequest.get("/api/Live/User/user-limit/");
};

const getAssertFromParentCode = (parentCode) => {
    return httprequest.get(`/api/Live/MobileAssets/?parentCode=${parentCode}`);
};

const getAllWorkOrders = (userId) => {
    return httprequest.get("/api/Live/MobileWorkOrder/?userId=" + userId + "&allWOs=true");
};

const getWorkOrderTypes = () => {
  return httprequest.get("/api/Live/Workordertype/?");
};

const getFailures = (params) => {
    return httprequest.get("/api/Live/Failures/" + params);
};

const getFilteredWorkOrderTypes = (assetCode) => {
    return httprequest.get(`/api/Live/Workordertype/?assetCode=${assetCode}`);
};

const getMaintenanceTypes = (workOrderType, assetCode) => {
  return httprequest.get(`/api/Live/MaintenanceType/?workOrderType=${workOrderType}&assetCode=${assetCode}`);
};

const getMaintenancePlant = () => {
  return httprequest.get("/api/Live/Warehouses/");
};

const getMaintenancePlantByUser = (userId) => {
  return httprequest.get("/api/Live/Warehouses/?userId="+userId);
};

const getReplacementTypes = () => {
    return httprequest.get("/api/Live/ReplacementType/?all=true");
};

const getRotations = () => {
    return httprequest.get("/api/Live/Rotation/?all=true");
};

const getAllProjects = () => {
  return httprequest.get("/api/Live/Projects/");
};

const getUserRoles = () => {
    return httprequest.get("/api/Live/UserRoles/?display=true");
};

const getUserProfiles = () => {
    return httprequest.get("/api/Live/Profile/");
};

const getOperationsfromgroup = (group) => {
    return httprequest.get(`api/Live/MobileTasks/?group=${group}`);
};

const getWorkOrderLabor = (workOrderCode) => {
  return httprequest.get(`api/Live/MobileLabour/?workOrder=${workOrderCode}`);
}

const getWorkOrderParts = (workOrderCode) => {
  return httprequest.get(`api/Live/WorkOrderResources/${workOrderCode}`);
}


const getAttachments = (rootFolder , id) => {
  return httprequest.get(`api/Live/Attachment/?rootFolder=${rootFolder}&id=${id}`);
}
const getAttachmentFile = (attachmentId) => {
  return httprequest.get(`api/Live/Attachment/${attachmentId}`, {
    responseType: 'blob'
  });
}

const getWorkOrderAttachments = (workOrderCode) => {
  return httprequest.get(`api/Live/Attachments/?workOrderCode=${workOrderCode}`);
}
const getWorkRequestAttachments = (workOrderCode) => {
  return httprequest.get(`api/Live/WorkRequestAttachments/?workRequestID=${workOrderCode}`);
}
const getWorkOrderAttachmentFile = (attachmentId) => {
  return httprequest.get(`api/Live/Attachments/${attachmentId}`, {
    responseType: 'blob'
  });
}

const getWorkOrderFailure = (workOrderCode) => {
  return httprequest.get(`api/Live/WorkOrderFailures/${workOrderCode}`);
}

const getWorkOrderFleet = (workOrderCode) => {
  return httprequest.get(`api/Live/FleetTracker/?workOrderCode=${workOrderCode}`);
}

const getFleetData = (assetCategory, workOrderCode) => {
    return httprequest.get(`api/Live/FleetTracker/data/?assetCategory=${assetCategory}&workOrderCode=${workOrderCode}`);
}

const getWorkRequests = (userId) => {
    return httprequest.get(`api/Live/WorkRequest/?userId=${userId}`);
}

const getWorkOrderMeterReadings = (assetCode, workOrderCode) => {
    return httprequest.get(`api/Live/WorkOrderMeterReadings/?AssetCode=${assetCode}&WorkOrderCode=${workOrderCode}`);
}

const getMeterList = () => {
    return httprequest.get('/api/Live/Meter/?list=true');
}

const executeSSO = () => {
    return httprequest.get('/api/live/ssoauthorize');
}

const consumeSSO = (Id) => {
    return httprequest.get('/api/samlConsume/' + Id);
}
const GetAnalyticsDashboards = (AnalyticsCategory) => {
    return httprequest.get(`/api/live/AnalyticsDashboards/?AnalyticsCategory=${AnalyticsCategory}`);
};

const getCrewList = () => {
    return httprequest.get('/api/Live/Crews/?crewList=true');
}

const getAllCrews = () => {
    return httprequest.get('/api/live/Crews/?getall=true');
}

const getCrewAssets = (crewName, crewId) => {
    return httprequest.get(`/api/live/CrewAssets/?crewname=${crewName}&crewid=${crewId}`);
}

const getCrewMembers = (Name, crewId) => {
    return httprequest.get(`/api/live/CrewMembers/?Name=${Name}&crewid=${crewId}`);
}

const getCrewAssetList = () => {
    return httprequest.get(`/api/live/Assets/?noCrew=true`);
}

const getInstallAssetList = (typename) => {
  return httprequest.get(`/api/Live/Assets/?assetCategory=${typename}`);
}


const getSubComponentsParts = () => {
    return httprequest.get(`/api/Live/FtsubComponentParts`);
}

const getSubComponentsAreas = () => {
    return httprequest.get(`/api/Live/FtsubComponentAreas`);
}

const getFleetRotation = () => {
    return httprequest.get(`/api/Live/Ftheader`);
}
const getDismantleWorkOrder = (workOrderCode) => {
  return httprequest.get(`/api/Live/Rotables/?workOrderCode=`+workOrderCode);
}
const getAssets = (treeview, PageNumber, PageRows) => {
    return httprequest.get(`/api/Live/Assets/?treeview=${treeview}&PageNumber=${PageNumber}&PageRows=${PageRows}`);
}

const getSessionUser = () => {
    return httprequest.get(`/api/SessionUser`);
}

const getRequestNotes = (requestId) => {
    return httprequest.get(`/api/Live/WorkNotes/?workRequestId=${requestId}`);
}

const getWorkOrderNotes = (workorder) => {
    return httprequest.get(`/api/Live/WorkNotes/?workOrderId=${workorder}`);
}

const getPersonnelPlants = (personnelId) => {
    return httprequest.get(`/api/Live/MultiPlantPersonnel/?personnelId=${personnelId}`);
}

const getUserPlants = (userId) => {
    return httprequest.get(`/api/Live/MultiPlantPersonnel/?userId=${userId}`);
}

const getWorkOrderHistoryLog = (workorder) => {
    return httprequest.get(`/api/Live/WorkOrderLogs/${workorder}`);
}

// post

const createWorkOrder = (payld) => {
  const payload = {...payld};
  delete payload.estimatedDurationHours;
  delete payload.estimatedDurationMinutes;
  delete payload.jobDetails;
  delete payload.groupTaskDescription;

  return httprequest.post("/api/Live/MobileWorkOrder/", payload);
};

const createWorkOrderRequest = (requestId, payld) => {
  const payload = {...payld};
    delete payload.estimatedDurationHours;
    delete payload.estimatedDurationMinutes;
    delete payload.incidentDate;
    delete payload.jobDetails;
    delete payload.groupTaskDescription;

  return httprequest.post("/api/Live/HandleWorkRequest/?workRequestID="+requestId, payload);
};
const createWorkOrderLabor = (payload) => {
  return httprequest.post("/api/Live/MobileLabour/", payload);
}
const createWorkOrderAttachment = (woCode, fileName, payload, type) => {
    return httprequest.post(`/api/Live/Attachments/?workOrderCode=${woCode}&fileName=${fileName}`, payload, {
        headers: {
            'Content-Type': type,
            'description': (new Date()).toLocaleDateString()
    }});
        }
const createAttachment = (rootFolder, id, fileName, payload, type) => {
  return httprequest.post(`/api/Live/Attachment/?id=${id}&fileName=${fileName}`, payload, {
    headers: {
      'Content-Type': type,
      'description': (new Date()).toLocaleDateString(),
       'rootFolder': rootFolder,
    }});
}

const createWorkRequestAttachment = (woCode, fileName, payload, type) => {
  return httprequest.post(`/api/Live/WorkRequestAttachments/?workRequestID=${woCode}&fileName=${fileName}`, payload, {
        headers: {
            'Content-Type': type,
            'description': (new Date()).toLocaleDateString()
    }});
}
const createPerson = (payload) => {
    payload.name = payload.firstName + ' ' + payload.lastName;
    return httprequest.post("/api/Live/Personnel/", payload);
};

const createUser = (payload) => {
    return httprequest.post("/api/Live/User/", payload);
};

const createWorkOrderFailure = (payload) => {
    return httprequest.post("/api/Live/WorkOrderFailures/", payload);
};

const createWorkOrderFleet = (payload) => {
  return httprequest.post("/api/Live/FleetTracker/", payload);
};

const createWorkRequest = (payload) => {
  return httprequest.post("/api/Live/WorkRequest/", payload);
};

const setEnvironment = (environment) => {
    return httprequest.post("/api/Environment/?environment=" + environment);
};

const createUserRole = (payload) => {
    return httprequest.post("/api/Live/UserRoles/", payload);
};

const updateMeterReadings = (payload) => {
  return httprequest.post("/api/Live/WorkOrderMeterReadings/", payload);
};

const createCrew = (payload) => {
    return httprequest.post("/api/live/Crews/", payload);
};

const createCrewAsset = (payload) => {
    return httprequest.post("/api/live/CrewAssets/", payload);
};

const createCrewMembers = (payload) => {
    return httprequest.post("/api/live/CrewMembers/", payload);
};

const createRequestOrderNotes = (note) => {
  return httprequest.post(`/api/Live/WorkNotes/`, note);
}

const createDismantle = (payload) => {
  return httprequest.post(`/api/Live/Rotables/`, payload);
}

const createAsset = (payload) => {
  return httprequest.post("/api/Live/Assets/", payload);
};

// put

const retryWorkOrderSyncs = (id) => {
    return httprequest.put("/api/Live/WorkOrderSyncRetry/" + id);
};

const updatePerson = (payload) => {
    payload.name = payload.firstName + ' ' + payload.lastName;
    return httprequest.put("/api/Live/Personnel/" + payload.id, payload);
};

const updateWorkOrderStatus = (payload) => {
  return httprequest.put("/api/Live/WorkOrderStatus/", payload);
};

const updateUser = (payload) => {
    return httprequest.put("/api/Live/User/" + payload.userId, payload);
};

const updateWorkRequest = (id, payload) => {
  return httprequest.put("/api/Live/HandleWorkRequest/" + id, payload);
};
const updateWorkOrder = (id, payload) => {
  return httprequest.put("/api/Live/WorkOrderDetailsUpdate/" + id, payload);
};
const updateUserRole = (payload) => {
    return httprequest.put("/api/Live/UserRoles/" + payload.userRoleId, payload);
};

const updateCrew = (payload) => {
  return httprequest.put("/api/Live/Crews/" + payload.crewId, payload);
};

const updateCrewStatus = (id) => {
  return httprequest.put("/api/Live/Crews/statuschange/" + id);
};

const valideDismantle = (id, payload) => {
  return httprequest.put("/api/Live/Rotables/" + id, payload);
};

// DELETE
const deleteAsset = (payload) => {
    return httprequest.delete("/api/live/CrewAssets/", { data: payload });
};

const deleteMember = (id) => {
    return httprequest.delete("/api/live/CrewMembers/" + id);
};

export default {
    getAllAssets,
    getAssertFromParentCode,
    getWorkOrderTypes,
    getFailures,
    getAllUsers,
    getMaintenancePlant,
    getMaintenanceTypes,
    getAllProjects,
    getOperationsfromgroup,
    createWorkOrder,
    getWorkOrderLabor,
    createWorkOrderLabor,
    getWorkOrderParts,
    getWorkOrderAttachments,
    getWorkOrderAttachmentFile,
    createWorkOrderAttachment,
    getAllPersonnel,
    createPerson,
    updatePerson,
    getWorkOrderFailure,
    createWorkOrderFailure,
    getAllSettingsUsers,
    getUserLimits,
    getWorkOrderFleet,
    getFleetData,
    createWorkOrderFleet,
    updateWorkOrderStatus,
    getUserRoles,
    createUser,
    updateUser,
    getNonUserPersonnel,
    getWorkRequests,
    createWorkRequest,
    setEnvironment,
    updateWorkRequest,
    createWorkOrderRequest,
    getAssetFromCode,
    createWorkRequestAttachment,
    getWorkRequestAttachments,
    getUserProfiles,
    createUserRole,
    updateUserRole,
    getWorkOrderMeterReadings,
    updateMeterReadings,
    executeSSO,
    consumeSSO,
    updateWorkOrder,
    GetAnalyticsDashboards,
    getAllCrews,
    createCrew,
    createCrewAsset,
    getCrewAssets,
    deleteAsset,
    updateCrew,
    getCrewAssetList,
    getCrewMembers,
    createCrewMembers,
    deleteMember,
    getAllPersonnelCrew,
    getSubComponentsAreas,
    getSubComponentsParts,
    getFleetRotation,
    getAssets,
    getSessionUser,
    getRequestNotes,
    getWorkOrderNotes,
    createRequestOrderNotes,
    retryWorkOrderSyncs,
    getLeads,
    getLabourUsers,
    getCrewList,
    getMaintenancePlantByUser,
    getRotations,
    getReplacementTypes,
    getPersonnelPlants,
    getUserPlants,
    getAllPersonnelWithPlants,
    updateCrewStatus,
    getDismantleWorkOrder,
    getInstallAssetList,
    createDismantle,
    valideDismantle,
    getAllAssetLogs,
    getFilteredWorkOrderTypes,
    getAllWorkOrders,
    getAttachments,
    createAttachment,
    getAttachmentFile,
    createAsset,
    getAssetFromCodeMain,
    getMeterList,
    getAllAssetRecentActivityLogs,
    getWorkOrderHistoryLog
};
