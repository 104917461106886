import React from "react";

export const FleetAnalysisConfig = {
  settings: {
    layout: {
          config: {
            rightSidePanel: {
                display: false
            }
          }
    }
  },
  routes: [
    {
          path: "/analytics/fleet-analysis",
          component: React.lazy(() => import("./FleetAnalysis"))
    }
  ]
};
