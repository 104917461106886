import React, { useEffect } from 'react';
import { Button, InputAdornment, Icon, Typography, Link, FormControlLabel, Checkbox  } from '@material-ui/core';
import { TextFieldFormsy } from '../../../../@fuse';
import Formsy from 'formsy-react';
import * as authActions from '../../../../app/auth/store/actions';
import * as Actions from '../../../../app/store/actions';
import { useDispatch } from 'react-redux';


function FTSLogin(props) {
    /*const dispatch = useDispatch();

    useEffect(() => {
        showDialog();

        auth0Service.onAuthenticated(() => {
            dispatch(Actions.showMessage({ message: 'Logging in with Auth0' }));

            auth0Service.getUserData().then(tokenData => {
                dispatch(authActions.setUserDataAuth0(tokenData));

                dispatch(Actions.showMessage({ message: 'Logged in with Auth0' }));
            });
        });
    }, [dispatch]);*/

    //auth0Service.login();
    function redirect() {
        window.location.href = "https://app.fracttal.com/rpc/auth/ftsi"
    }

    return (
        <div className="w-full">
         
            <Formsy
              
                className="flex flex-col justify-center w-full"
            >
                <TextFieldFormsy
                    className="mb-16"
                    type="text"
                    name="username"
                    label="Email"
                    validations={{
                        minLength: 4
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 4'
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">email</Icon></InputAdornment>
                    }}
                    variant="outlined"
                    required
                />

                <TextFieldFormsy
                    className="mb-16"
                    type="password"
                    name="password"
                    label="Password"
                    validations={{
                        minLength: 4
                    }}
                    validationErrors={{
                        minLength: 'Min character length is 4'
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">vpn_key</Icon></InputAdornment>
                    }}
                    variant="outlined"
                    required
                />

                <div className="flex items-center justify-between mb-16">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="chkRememberMe"
                                        color="secondary"
                                    />
                                }
                                label="Remember Me"
                            />           
                            <Typography>
                                <Link href="#">Forgot Password</Link>
                            </Typography>
                </div>
               
               
               
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="w-full mx-auto normal-case mt-16 mb-15"
                        aria-label="LOG IN"
                        value="ftsLogin"
                    >
                        Login
                </Button>
         
                    <Typography className="items-center mt-15"><b>OR</b></Typography>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className="w-full mx-auto normal-case mt-16"
                        aria-label="LOG IN"
                        value="ftsLogin"
                    >
                        Log in with SSO
                </Button>
               
              
               
            </Formsy>
        </div>
    );
}

export default FTSLogin;