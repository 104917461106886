import React from "react";
import { Redirect } from "react-router-dom";

export const ScrumboardAppConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
          path: "/maintenance-management/work-order-board/boards/:boardId/:boardUri?",
      component: React.lazy(() => import("./board/Board"))
    },
    {
        path: "/maintenance-management/work-order-board/boards",
      component: React.lazy(() => import("./boards/Boards"))
    },
    {
        path: "/maintenance-management/work-order-board",
        component: () => <Redirect to="/asset-management/work-order-board/boards" />
    }
  ]
};
