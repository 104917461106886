import React, { useEffect, useState } from 'react';
import { FuseNavigation } from '../../../@fuse';
import clsx from 'clsx';
import {useSelector} from 'react-redux';

function Navigation(props)
{
    const navigation = useSelector(({ fuse }) => fuse.navigation);
    const user = useSelector(({ auth }) => auth.user);
    const [filteredNavigation, setFilteredNavigation] = useState(navigation);

    function isVisible(item) {
        if ((item.id === "crews" && !user.data.info.canAccessCrews)
            || (item.id === "analytics" && !user.data.info.canAccessAnalytics)
            || (item.id === "asset-management" && !user.data.info.viewAsset)
            || !item.access.includes(user.profile)) {
            return false;
        }
        else {
            return true;
        }
    }
    
    useEffect(() => {
        let filter = navigation.filter(item => isVisible(item)).map(item => ({
            ...item,
            children: item.children
                .filter(child => isVisible(child))
        }));

        setFilteredNavigation(filter);

    }, [user]);

    return (
        <FuseNavigation className={clsx("navigation", props.className)} navigation={filteredNavigation} layout={props.layout} dense={props.dense} active={props.active}/>
    );
}

Navigation.defaultProps = {
    layout: "vertical"
};

export default Navigation;
