import React, { useState } from "react";
import { Card, CardContent, Typography, Tabs, Tab } from "@material-ui/core";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { FuseAnimate } from "../../../@fuse";
import { Link } from "react-router-dom";
import clsx from "clsx";
import JWTLoginTab from "./tabs/JWTLoginTab";
import Auth0LoginTab from "./tabs/Auth0LoginTab";
import SamlLoginTab from "./tabs/SamlLoginTab";
import FracttalProductionTab from "./tabs/FracttalProductionTab";
import FracttalProdLogin from "./tabs/FracttalProdLogin";
import FTSTab from "./tabs/FTSLoginTab";
import { makeStyles } from "@material-ui/styles";
import logo from "../Icons/FTSI-Logo.png";
import poweredByLogo from "../Icons/powered-by-iox.png";
import prodLogo from "../Icons/FTSI_Logo_Only_PROD.png";
import ssoLogo from "../Icons/microsoft-icon.png";

const useStyles = makeStyles((theme) => ({
  root: {
    background:
      "linear-gradient(to right, " +
      theme.palette.primary.dark +
      " 0%, " +
      darken(theme.palette.primary.dark, 0.5) +
      " 100%)",
    color: theme.palette.primary.contrastText,
  },
  quote: {
    backgroundColor: "#d1d1d1",
    backgroundImage: "url(/images/Profrac-Logo.png)",
    backgroundSize: "default",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "35% 50%",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    marginTop: "20%",
    color: "#ffffff", //#00305B
    fontWeight: 300,
  },
}));

function Login() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(4); //4 for publish, 1 for testing

  function handleTabChange(event, value) {
    setSelectedTab(value);
  }

  return (
    <div
      className={clsx(
        classes.root,
        classes.quote,
        "flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0"
      )}
    >
      <div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left" />
      <FuseAnimate animation={{ translateX: [0, "100%"] }}>
        <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
          <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
            <img
              src="/images/Profrac-Logo.png"
              alt="Main Logo"
              style={{ width: "300px", height: "100px" }}
            />
            <Typography variant="h6" className="text-center md:w-full mb-48">
              LOGIN TO YOUR ACCOUNT
            </Typography>

            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="fullWidth"
              className="w-full mb-32"
            >
              <Tab
                icon={<img className="h-40 p-4" src={ssoLogo} alt="" />}
                className="min-w-0"
                label="SSO"
                value={4}
              />
            </Tabs>

            {/*selectedTab === 1 && <JWTLoginTab />*/}
            {selectedTab === 4 && <FracttalProdLogin />}
            {/*selectedTab === 5 && <FTSTab />*/}

            <div className="">
              <img
                src={poweredByLogo}
                alt="Powered by Logo"
                style={{
                  width: "85",
                  height: "50px",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  padding: 5,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </FuseAnimate>
    </div>
  );
}

export default Login;
