import React from 'react';

export const ComingSoonPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        //{
        //    path: '/asset-management/assets',
        //    component: React.lazy(() => import('./ComingSoonPage'))
        //},
        //{
        //    path: '/asset-management/classification-schemes',
        //    component: React.lazy(() => import('./ComingSoonPage'))
        //}
    ]
};
