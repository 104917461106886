import React from "react";

export const F3ReportingConfig = {
  settings: {
    layout: {
      config: {
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: "/analytics/f3-reporting",
      component: React.lazy(() => import("./F3Reporting")),
    },
  ],
};
