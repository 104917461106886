import React from 'react';
import { authRoles } from '../../../auth';

export const SamlConsumeConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: "/uat/saml-consume/:Id",
            component: React.lazy(() => import("./SamlConsume"))
        },
        {
            path: "/uat/saml-consume",
            component: React.lazy(() => import("./SamlConsume"))
        }
    ]
};