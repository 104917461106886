import navigationConfig from './navigationConfig';
import devnavigationconfig from './devnavigationconfig';
import livenavigationconfig from './livenavigationconfig';
import workorderService from '../services/httpService/workorderService';

export default function navListConfig() {
    workorderService.getSessionUser().then((response) => {
        const data = response.data

        if (data.environment == "DEV") {
            return devnavigationconfig;
        }
        else if (data.environment == "Live") {
            return livenavigationconfig;
        }
        else {
            return navigationConfig;
        }
    });
};