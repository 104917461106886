import React, { useState, useEffect } from "react";
import { LicenseManager } from "ag-grid-enterprise";
import moment from "moment";
import { green, red } from "@material-ui/core/colors";

import SubtaskNone from "../Icons/icons8-task-100.png";
import SubtaskCompleted from "../Icons/icons8-task-100-2.png";
import SubtaskNoMandatory from "../Icons/icons8-task-100-3.png";
import SubtaskPartial from "../Icons/icons8-task-100-4.png";

import LaborNone from "../Icons/icons8-work-96.png";
import LaborEst from "../Icons/icons8-work-96-4.png";
import LaborComplete from "../Icons/icons8-work-96-2.png";

import PartsNone from "../Icons/icons8-list-of-parts-96-5.png";
import PartsEst from "../Icons/icons8-list-of-parts-96-4.png";
import PartsComplete from "../Icons/icons8-list-of-parts-96-2.png";

import StatusIncomplete from "@material-ui/icons/Error";
import StatusPartial from "../Icons/icons8-warning-96.png";
import StatusComplete from "@material-ui/icons/CheckCircle";

const iconStyle = { color: "White", width: "32px", height: "32px" };

export let signIn = false;

export function LogIn() {
  signIn = true;
}

export function setLicenseKey(agKey) {
  LicenseManager.setLicenseKey(agKey);
}

export const defaultColDef = {
  editable: false,
  resizable: true,
  sortable: true,
  filter: "agTextColumnFilter",
  menuTabs: ["filterMenuTab"],
  filterParams: {
    filterOptions: ["contains"],
    maxNumConditions: 1,
    buttons: ["reset", "apply"],
  },
};

export const dateFormatter = {
  valueFormatter: ({ value }) => {
    if (!value) {
      return "";
    }

    return moment.utc(value).local().format("MMMM DD, YYYY HH:mm:ss");
  },
};

export const FormatNormalDate = (value) => {
  if (!value) {
    return "";
  }

  return moment.utc(value).local().format("MMMM DD YYYY HH:mm:ss");
};

export const dateCompare = (filterLocalDateAtMidnight, cellValue) => {
  const dateAsString = cellValue;

  if (dateAsString == null) {
    return 0;
  }

  const dateParts = dateAsString.split("-");
  const year = Number(dateParts[0]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[2].split("T")[0]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

export const TasksGroupRootFolder = "TaskGroup";

export const Result = [
  { Id: 1, name: "Pass", colour: "bg-green-300" },
  { Id: 2, name: "Fail", colour: "bg-red-300" },
  { Id: 3, name: "N/A", colour: "bg-grey-500" },
];

export const analytics = [
  {
    name: "Devices and Sensors",
    tabs: [
      {
        name: "Energy Usage",
        link: "https://analytics.zoho.com/open-view/1575472000004895076/ba86218ba1357fa72273a82040a96fc6",
      },
      {
        name: "Water Usage",
        link: "https://analytics.zoho.com/open-view/1575472000004868840/1113c3d971b097adc8f51c2643522e2c",
      },
      {
        name: "Carbon Emissions",
        link: "https://analytics.zoho.com/open-view/1575472000000582557/ac79917940019c9bbb92bb972d694d8d",
      },
      {
        name: "HVAC Monitoring",
        link: "https://analytics.zoho.com/open-view/1575472000004895076/ba86218ba1357fa72273a82040a96fc6",
      },
      {
        name: "Lifts & Escalators",
        link: "https://analytics.zoho.com/open-view/1575472000004895076/ba86218ba1357fa72273a82040a96fc6",
      },
      {
        name: "Footfall Monitoring",
        link: "https://analytics.zoho.com/open-view/1575472000004895076/ba86218ba1357fa72273a82040a96fc6",
      },
      {
        name: "Fire Protection",
        link: "https://analytics.zoho.com/open-view/1575472000004895076/ba86218ba1357fa72273a82040a96fc6",
      },
      {
        name: "Cost Analysis",
        link: "https://analytics.zoho.com/open-view/1575472000004895076/ba86218ba1357fa72273a82040a96fc6",
      },
      {
        name: "Security",
        link: "https://analytics.zoho.com/open-view/1575472000004895076/ba86218ba1357fa72273a82040a96fc6",
      },
    ],
  },
  {
    name: "Maintenance Analysis",
    tabs: [
      {
        name: "Work Requests",
        link: "https://analytics.zoho.com/open-view/1575472000008507093/5cacea08895b6e6eea115a1ac77be1b2",
      },
      {
        name: "Open Work",
        link: "https://analytics.zoho.com/open-view/1575472000008507104/5049613d0c2b37902cabb7591b5dddc5",
      },
      {
        name: "Closed Work",
        link: "https://analytics.zoho.com/open-view/1575472000008507115/d93b1b1a8d7fbc1b355fd5255f772137",
      },
      {
        name: "Maintenance Spend",
        link: "https://analytics.zoho.com/open-view/1575472000008507147/3669bb3960798d1f7e0f153acb2a50f0",
      },
      {
        name: "Planned Maintenance",
        link: "https://analytics.zoho.com/open-view/1575472000008507159/3411f33b958953d6d1b337a1877eef4d",
      },
      {
        name: "Maintenance Forecasting",
        link: "https://analytics.zoho.com/open-view/1575472000008507171/85fb00d8057567b917e5074a0fb5367d",
      },
      {
        name: "Historical Data",
        link: "https://analytics.zoho.com/open-view/1575472000007249602/cb5ebe0991ea7544a0c9b5c362b78af4",
      },
      /*{
          name: "Failure Analysis",
        link:
          "https://analytics.zoho.com/open-view/1575472000008507183/2418293ef30660b5cc0d5acace4b81d0"
      },
      {
        name: "Failure Analysis & Performance",
        link:
          "https://analytics.zoho.com/open-view/1575472000006098215/91e28eb6504f3a258ca863623f3053b8"
      },
      {
        name: "MRO Spares",
        link:
          "https://analytics.zoho.com/open-view/1575472000006098241/0f15989fa09c29b2ae0fd16b357b3d84"
      },
      {
        name: "Spares Forecasting",
        link:
          "https://analytics.zoho.com/open-view/1575472000006098267/50517132db39333a7fca1cbb7b72fb85"
      }*/
    ],
  },
  {
    name: "Equipment Analysis",
    tabs: [
      {
        name: "Failures",
        link: "https://analytics.zoho.com/open-view/1575472000008507183/2418293ef30660b5cc0d5acace4b81d0",
      },
      {
        name: "Fleet Tracker",
        link: "https://analytics.zoho.com/open-view/1575472000008643247/ba3e3cb6ffb1f72e3ee7b033c5cafb64",
      },
      {
        name: "Blenders",
        link: "https://analytics.zoho.com/open-view/1575472000008957881/5d97dc4fd630ddbc1656d7e94aea9386",
      },
      {
        name: "Frac Pumps",
        link: "https://analytics.zoho.com/open-view/1575472000008958155/bb154d54f6e2a8feacaf99357069d7b0",
      },
      {
        name: "Meter Readings",
        link: "https://analytics.zoho.com/open-view/1575472000008643263/0a2e8a8df7a164eaac0901ed370fa43e",
      } /*,
          {
              name: "Other Units",
            link:
              "https://analytics.zoho.com/open-view/1575472000008643263/0a2e8a8df7a164eaac0901ed370fa43e"
          }*/,
    ],
  },
  {
    name: "Live Maintenance Analysis",
    tabs: [
      {
        name: "Work Requests",
        link: "https://analytics.zoho.com/open-view/1575472000010385263/8f2b38cdb4696b06d655816f31df1100",
      },
      {
        name: "Open Work",
        link: "https://analytics.zoho.com/open-view/1575472000010385789/0a54f7bbaff6e856ddf6b73ef7fc81a8",
      },
      {
        name: "Closed Work",
        link: "https://analytics.zoho.com/open-view/1575472000010320401/87b59c24c541eb2a2b8f2f8052922c92",
      },
      {
        name: "Maintenance Spend",
        link: "https://analytics.zoho.com/open-view/1575472000010385809/5cec16eece1aa868b0456d3b4544fd98",
      },
      {
        name: "Planned Maintenance",
        link: "https://analytics.zoho.com/open-view/1575472000010385825/4f6722af45264102d25bfae830948e17",
      },
      {
        name: "Historical Data",
        link: "https://analytics.zoho.com/open-view/1575472000010385837/3880fc3a1faa941ebe46881b34fe1b53",
      },
    ],
  },
  {
    name: "Live Equipment Analysis",
    tabs: [
      {
        name: "Failures",
        link: "https://analytics.zoho.com/open-view/1575472000010385849/131977584133119b3ea5b02129e09aaf",
      },
      {
        name: "Fleet Tracker",
        link: "https://analytics.zoho.com/open-view/1575472000010385861/456018c0fb28c16ead92dc3f3873c718",
      },
      {
        name: "Blenders",
        link: "https://analytics.zoho.com/open-view/1575472000010385873/b8d6b7ffc71b385e3a97266c024c1576",
      },
      {
        name: "Frac Pumps",
        link: "https://analytics.zoho.com/open-view/1575472000010385885/01a93959c8a6691039d43c9de2d9e4aa",
      },
      {
        name: "Meter Readings",
        link: "https://analytics.zoho.com/open-view/1575472000010385897/22eff7dcbb6e2ecbd7d554c6f3f69488",
      },
      {
        name: "Equipment Health Report",
        link: "https://analytics.zoho.com/open-view/1575472000010860750/34edb433910cd5a50e26b4057c218702",
      },
    ],
  },
  {
    name: "COVID-19",
    tabs: [
      {
        name: "United States COVID-19",
        link: "https://analytics.zoho.com/open-view/1575472000009142232/f0dca4bd3c5580ec40492dc725e21e08",
      },
      {
        name: "Continental COVID-19",
        link: "https://analytics.zoho.com/open-view/1575472000009142536/010f060480ae34db5fe3b403c9089aa1",
      },
    ],
  },
];

export const inventoryItemsKanban = {
  id: "1",
  name: "Inventory Items Kanban",
  uri: "inventory-items-kanban",
  settings: {
    color: "fuse-dark",
    subscribed: false,
    cardCoverImages: true,
  },
  lists: [
    {
      id: "56027cf5a2ca3839a5d36103",
      name: "Open",
      idCards: [
        "5603a2a3cab0c8300f6096b3",
        "44d1.2b51ea6cc2b5d.21f4a3412e857.8ffa2d8b44ad9.ac87215ed53a1.67d4921ad8f8d.9f318bcb2",
      ],
    },
    {
      id: "56127cf2a2ca3539g7d36103",
      name: "In Process",
      idCards: [
        "2837273da9b93dd84243s0f9",
        "5787b7e4740c57bf0dffd5b6",
        "5637273da9b93bb84743a0f9",
        "7987.9740ba532b0d4.f9d12243f7362.507c0738dc561.87fba0a03df6e.75e6508cacf10.7a9835b54",
      ],
    },
    {
      id: "faf244627326f1249525763d",
      name: "Completed",
      idCards: [
        "d9005a4b89ed2aadca48a6ad",
        "f6b9d7a9247e5d794a081927",
        "80ed.24ad3b18e2668.f28fbbceeeff9.5a834620a42f1.5909be19a2bf2.6c4a54947ce2d.da356b0c1",
        "0ad2.7862f947bc456.f42b446df54cb.d1dd9e93601a1.9deb1406d1404.0b3c278fc7001.733341b42",
        "bad3.51be8ad33acaf.9540ecb37f7e8.6bee596cfe7d3.44c68bee289c4.b96ed0b9f0af7.e14846035",
      ],
    },
    {
      id: "ad7d.9fffac5dff412.c83bca6853767.8fd7549b2b1ca.ceda8a01774c4.a5cf3976e87e4.ce79eeeea",
      name: "Collected",
      idCards: [
        "acc6.9c673cd2f5e35.521e91d8d5991.4b2a95e0539d1.027930c0743c5.7ad1ea7bea476.e8fbe6347",
        "3279.3d69b40cc0b75.690252b6bea08.1e1789b0b7c2e.2f264b8661ce2.84d5f56910e23.429be5e8a",
        "ba01.8e1a43f92a03a.0022bd5cbb9ba.275c64d911d8c.880e0846a3966.f75ff43e53ad.48ad612e7",
      ],
    },
  ],
  cards: [
    {
      id: "2837273da9b93dd84243s0f9",
      name: "Update generators",
      description: "Current generator doesn't support Node.js 6 and above.",
      idAttachmentCover: "",
      idMembers: ["26027s1930450d8bf7b10828"],
      idLabels: ["26022e4129ad3a5sc28b36cd"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message: "AngularCLI could be a nice alternative.",
          time: "now",
        },
      ],
      due: null,
    },
    {
      id: "5603a2a3cab0c8300f6096b3",
      name: "Change background colors",
      description: "",
      idAttachmentCover: "67027cahbe3b52ecf2dc631c",
      idMembers: ["76027g1930450d8bf7b10958"],
      idLabels: ["56027e4119ad3a5dc28b36cd", "5640635e19ad3a5dc21416b2"],
      attachments: [
        {
          id: "67027cahbe3b52ecf2dc631c",
          name: "mail.jpg",
          src: "assets/images/scrumboard/mail.jpg",
          time: "Added Nov 3 at 15:22AM",
          type: "image",
        },
        {
          id: "56027cfcbe1b72ecf1fc452a",
          name: "calendar.jpg",
          src: "assets/images/scrumboard/calendar.jpg",
          time: "Added Nov 1 at 12:34PM",
          type: "image",
        },
      ],
      subscribed: true,
      checklists: [
        {
          id: "63021cfdbe1x72wcf1fc451v",
          name: "Checklist",
          checkItems: [
            {
              id: 1,
              name: "Implement a calendar library",
              checked: false,
            },
            {
              id: 2,
              name: "Replace event colors with Material Design colors",
              checked: true,
            },
            {
              id: 3,
              name: "Replace icons with Material Design icons",
              checked: false,
            },
            {
              id: 4,
              name: "Use moment.js",
              checked: false,
            },
          ],
        },
        {
          name: "Checklist 2",
          id: "74031cfdbe1x72wcz1dc166z",
          checkItems: [
            {
              id: 1,
              name: "Replace event colors with Material Design colors",
              checked: true,
            },
            {
              id: 2,
              name: "Replace icons with Material Design icons",
              checked: false,
            },
            {
              id: 3,
              name: "Use moment.js",
              checked: false,
            },
          ],
        },
      ],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "56027c1930450d8bf7b10758",
          message: "We should be able to add moment.js without any problems",
          time: "12 mins. ago",
        },
        {
          id: 2,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message:
            "I added a link for a page that might help us deciding the colors",
          time: "30 mins. ago",
        },
        {
          id: 3,
          type: "attachment",
          idMember: "36027j1930450d8bf7b10158",
          message: "attached a link",
          time: "45 mins. ago",
        },
      ],
      due: "2017-08-29T10:16:34.000Z",
    },
    {
      id: "5637273da9b93bb84743a0f9",
      name: "Fix splash screen bugs",
      description: "",
      idAttachmentCover: "",
      idMembers: ["56027c1930450d8bf7b10758"],
      idLabels: ["5640635e19ad3a5dc21416b2"],
      attachments: [],
      subscribed: true,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "d9005a4b89ed2aadca48a6ad",
      name: "Add alternative authentication pages",
      description: "",
      idAttachmentCover: "",
      idMembers: ["36027j1930450d8bf7b10158"],
      idLabels: ["6540635g19ad3s5dc31412b2", "56027e4119ad3a5dc28b36cd"],
      attachments: [],
      subscribed: false,
      checklists: [
        {
          id: "dbfb.99bd0ad37dabc.e05046f0c824d.18f26bb524c96.78bebc8488634.240c0ee6a5e45.4cb872965",
          name: "Pages",
          checkItems: [
            {
              id: 1,
              name: "Login",
              checked: true,
            },
            {
              id: 2,
              name: "Register",
              checked: true,
            },
            {
              id: 3,
              name: "Lost Password",
              checked: false,
            },
            {
              id: 4,
              name: "Recover Password",
              checked: false,
            },
            {
              id: 5,
              name: "Activate Account",
              checked: false,
            },
          ],
        },
      ],
      activities: [],
      due: null,
    },
    {
      id: "5787b7e4740c57bf0dffd5b6",
      name: "Fix the console",
      description: "We need to fix the console asap!",
      idAttachmentCover: "",
      idMembers: [],
      idLabels: ["26022e4129ad3a5sc28b36cd"],
      attachments: [],
      subscribed: true,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message: "I'm on it!",
          time: "now",
        },
      ],
      due: "2020-09-07T12:00:00.000Z",
    },
    {
      id: "f6b9d7a9247e5d794a081927",
      name: "New media player",
      description: "",
      idAttachmentCover: "",
      idMembers: [
        "76027g1930450d8bf7b10958",
        "56027c1930450d8bf7b10758",
        "26027s1930450d8bf7b10828",
      ],
      idLabels: ["5640635e19ad3a5dc21416b2", "6540635g19ad3s5dc31412b2"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "acc6.9c673cd2f5e35.521e91d8d5991.4b2a95e0539d1.027930c0743c5.7ad1ea7bea476.e8fbe6347",
      name: "Memory Leak",
      description: "",
      idAttachmentCover: "",
      idMembers: ["36027j1930450d8bf7b10158"],
      idLabels: ["26022e4129ad3a5sc28b36cd", "5640635e19ad3a5dc21416b2"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "3279.3d69b40cc0b75.690252b6bea08.1e1789b0b7c2e.2f264b8661ce2.84d5f56910e23.429be5e8a",
      name: "Broken toolbar on profile page",
      description: "",
      idAttachmentCover: "",
      idMembers: ["26027s1930450d8bf7b10828"],
      idLabels: ["26022e4129ad3a5sc28b36cd"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message: "This should be a medium priority bug, shouldn't it?",
          time: "now",
        },
      ],
      due: null,
    },
    {
      id: "ba01.8e1a43f92a03a.0022bd5cbb9ba.275c64d911d8c.880e0846a3966.f75ff43e53ad.48ad612e7",
      name: "Button hover style",
      description:
        "If there are 3 or more buttons in certain page, weird flashing happens when you hover over the red ones.",
      idAttachmentCover: "",
      idMembers: ["26027s1930450d8bf7b10828"],
      idLabels: ["26022e4129ad3a5sc28b36cd", "5640635e19ad3a5dc21416b2"],
      attachments: [],
      subscribed: true,
      checklists: [],
      activities: [],
      due: "2017-03-08T09:00:00.000Z",
    },
    {
      id: "80ed.24ad3b18e2668.f28fbbceeeff9.5a834620a42f1.5909be19a2bf2.6c4a54947ce2d.da356b0c1",
      name: "New header designs",
      description: "",
      idAttachmentCover: "12027cafbe3b52ecf2ef632c",
      idMembers: [],
      idLabels: [
        "56027e4119ad3a5dc28b36cd",
        "6540635g19ad3s5dc31412b2",
        "5640635e19ad3a5dc21416b2",
      ],
      attachments: [
        {
          id: "12027cafbe3b52ecf2ef632c",
          name: "header-.jpg",
          src: "assets/images/scrumboard/header-1.jpg",
          time: "Added Nov 3 at 15:22AM",
          type: "image",
        },
        {
          id: "55027ced1e1a12ecf1fced2a",
          name: "header-2.jpg",
          src: "assets/images/scrumboard/header-2.jpg",
          time: "Added Nov 1 at 12:34PM",
          type: "image",
        },
      ],
      subscribed: false,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message: "Currently we have two new designs ready to ship.",
          time: "now",
        },
      ],
      due: null,
    },
    {
      id: "0ad2.7862f947bc456.f42b446df54cb.d1dd9e93601a1.9deb1406d1404.0b3c278fc7001.733341b42",
      name: "Fixed footer",
      description: "",
      idAttachmentCover: "",
      idMembers: ["26027s1930450d8bf7b10828", "56027c1930450d8bf7b10758"],
      idLabels: ["6540635g19ad3s5dc31412b2"],
      attachments: [],
      subscribed: true,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "bad3.51be8ad33acaf.9540ecb37f7e8.6bee596cfe7d3.44c68bee289c4.b96ed0b9f0af7.e14846035",
      name: "Collapsable navigation",
      description: "",
      idAttachmentCover: "",
      idMembers: [],
      idLabels: ["6540635g19ad3s5dc31412b2"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message:
            "I'm not sure why we re-doing the navigation. The current collapsable navigation works flawlessly.",
          time: "now",
        },
      ],
      due: null,
    },
    {
      id: "44d1.2b51ea6cc2b5d.21f4a3412e857.8ffa2d8b44ad9.ac87215ed53a1.67d4921ad8f8d.9f318bcb2",
      name: "Mail app new layout",
      description:
        "Current layout has lots of flaws in mobile. Outlook view should help with that.",
      idAttachmentCover: "",
      idMembers: [
        "56027c1930450d8bf7b10758",
        "26027s1930450d8bf7b10828",
        "76027g1930450d8bf7b10958",
        "36027j1930450d8bf7b10158",
      ],
      idLabels: ["56027e4119ad3a5dc28b36cd", "26022e4129ad3a5sc28b36cd"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "7987.9740ba532b0d4.f9d12243f7362.507c0738dc561.87fba0a03df6e.75e6508cacf10.7a9835b54",
      name: "API recover and monitoring",
      description: "We need a service to monitor and recover failed APIs.",
      idAttachmentCover: "",
      idMembers: ["36027j1930450d8bf7b10158", "76027g1930450d8bf7b10958"],
      idLabels: ["26022e4129ad3a5sc28b36cd", "5640635e19ad3a5dc21416b2"],
      attachments: [],
      subscribed: true,
      checklists: [
        {
          id: "6926.2b31d119e4a.889401e0ca7a0.13ad8ce2e569d.976e54e8b5d87.456afccd7e820.d6c77106a",
          name: "API Monitoring",
          checkItems: [
            {
              id: 1,
              name: "Simple dashboard design",
              checked: false,
            },
            {
              id: 2,
              name: "Should be able to see different time periods on the same dashboard",
              checked: true,
            },
            {
              id: 3,
              name: "Different colors for different clusters",
              checked: true,
            },
          ],
        },
        {
          id: "7c22.5261c7924387f.248e8b1d32205.003f7a9f501d1.1d48dcdbe8b23.8099dcc5f75a7.29a966196",
          name: "API Recovery",
          checkItems: [
            {
              id: 1,
              name: "Warning notifications to all developers",
              checked: false,
            },
            {
              id: 2,
              name: "Immediate recovery options attached to the notifications",
              checked: true,
            },
            {
              id: 3,
              name: "Backups every 6hours",
              checked: false,
            },
          ],
        },
      ],
      activities: [],
      due: "2017-02-02T11:20:34.000Z",
    },
  ],
  members: [
    {
      id: "56027c1930450d8bf7b10758",
      name: "Alice Freeman",
      avatar: "assets/images/avatars/alice.jpg",
    },
    {
      id: "26027s1930450d8bf7b10828",
      name: "Danielle Obrien",
      avatar: "assets/images/avatars/danielle.jpg",
    },
    {
      id: "76027g1930450d8bf7b10958",
      name: "James Lewis",
      avatar: "assets/images/avatars/james.jpg",
    },
    {
      id: "36027j1930450d8bf7b10158",
      name: "John Doe",
      avatar: "assets/images/avatars/Velazquez.jpg",
    },
  ],
  labels: [
    {
      id: "26022e4129ad3a5sc28b36cd",
      name: "High Priority",
      class: "bg-red text-white",
    },
    {
      id: "56027e4119ad3a5dc28b36cd",
      name: "Design",
      class: "bg-orange text-white",
    },
    {
      id: "5640635e19ad3a5dc21416b2",
      name: "App",
      class: "bg-blue text-white",
    },
    {
      id: "6540635g19ad3s5dc31412b2",
      name: "Feature",
      class: "bg-green text-white",
    },
  ],
};

export const workOrderKanban = {
  id: "1",
  name: "Work Order Kanban",
  uri: "work-order-kanban",
  settings: {
    color: "fuse-dark",
    subscribed: false,
    cardCoverImages: true,
  },
  lists: [
    {
      id: "56027cf5a2ca3839a5d36103",
      name: "Open",
      idCards: [
        "5603a2a3cab0c8300f6096b3",
        "44d1.2b51ea6cc2b5d.21f4a3412e857.8ffa2d8b44ad9.ac87215ed53a1.67d4921ad8f8d.9f318bcb2",
      ],
    },
    {
      id: "56127cf2a2ca3539g7d36103",
      name: "In Review",
      idCards: [
        "2837273da9b93dd84243s0f9",
        "5787b7e4740c57bf0dffd5b6",
        "5637273da9b93bb84743a0f9",
        "7987.9740ba532b0d4.f9d12243f7362.507c0738dc561.87fba0a03df6e.75e6508cacf10.7a9835b54",
      ],
    },
    {
      id: "faf244627326f1249525763d",
      name: "Reviewed",
      idCards: [
        "d9005a4b89ed2aadca48a6ad",
        "f6b9d7a9247e5d794a081927",
        "80ed.24ad3b18e2668.f28fbbceeeff9.5a834620a42f1.5909be19a2bf2.6c4a54947ce2d.da356b0c1",
        "0ad2.7862f947bc456.f42b446df54cb.d1dd9e93601a1.9deb1406d1404.0b3c278fc7001.733341b42",
        "bad3.51be8ad33acaf.9540ecb37f7e8.6bee596cfe7d3.44c68bee289c4.b96ed0b9f0af7.e14846035",
      ],
    },
    {
      id: "ad7d.9fffac5dff412.c83bca6853767.8fd7549b2b1ca.ceda8a01774c4.a5cf3976e87e4.ce79eeeea",
      name: "Closed",
      idCards: [
        "acc6.9c673cd2f5e35.521e91d8d5991.4b2a95e0539d1.027930c0743c5.7ad1ea7bea476.e8fbe6347",
        "3279.3d69b40cc0b75.690252b6bea08.1e1789b0b7c2e.2f264b8661ce2.84d5f56910e23.429be5e8a",
        "ba01.8e1a43f92a03a.0022bd5cbb9ba.275c64d911d8c.880e0846a3966.f75ff43e53ad.48ad612e7",
      ],
    },
  ],
  cards: [
    {
      id: "2837273da9b93dd84243s0f9",
      name: "Update generators",
      description: "Current generator doesn't support Node.js 6 and above.",
      idAttachmentCover: "",
      idMembers: ["26027s1930450d8bf7b10828"],
      idLabels: ["26022e4129ad3a5sc28b36cd"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message: "AngularCLI could be a nice alternative.",
          time: "now",
        },
      ],
      due: null,
    },
    {
      id: "5603a2a3cab0c8300f6096b3",
      name: "Change background colors",
      description: "",
      idAttachmentCover: "67027cahbe3b52ecf2dc631c",
      idMembers: ["76027g1930450d8bf7b10958"],
      idLabels: ["56027e4119ad3a5dc28b36cd", "5640635e19ad3a5dc21416b2"],
      attachments: [
        {
          id: "67027cahbe3b52ecf2dc631c",
          name: "mail.jpg",
          src: "assets/images/scrumboard/mail.jpg",
          time: "Added Nov 3 at 15:22AM",
          type: "image",
        },
        {
          id: "56027cfcbe1b72ecf1fc452a",
          name: "calendar.jpg",
          src: "assets/images/scrumboard/calendar.jpg",
          time: "Added Nov 1 at 12:34PM",
          type: "image",
        },
      ],
      subscribed: true,
      checklists: [
        {
          id: "63021cfdbe1x72wcf1fc451v",
          name: "Checklist",
          checkItems: [
            {
              id: 1,
              name: "Implement a calendar library",
              checked: false,
            },
            {
              id: 2,
              name: "Replace event colors with Material Design colors",
              checked: true,
            },
            {
              id: 3,
              name: "Replace icons with Material Design icons",
              checked: false,
            },
            {
              id: 4,
              name: "Use moment.js",
              checked: false,
            },
          ],
        },
        {
          name: "Checklist 2",
          id: "74031cfdbe1x72wcz1dc166z",
          checkItems: [
            {
              id: 1,
              name: "Replace event colors with Material Design colors",
              checked: true,
            },
            {
              id: 2,
              name: "Replace icons with Material Design icons",
              checked: false,
            },
            {
              id: 3,
              name: "Use moment.js",
              checked: false,
            },
          ],
        },
      ],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "56027c1930450d8bf7b10758",
          message: "We should be able to add moment.js without any problems",
          time: "12 mins. ago",
        },
        {
          id: 2,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message:
            "I added a link for a page that might help us deciding the colors",
          time: "30 mins. ago",
        },
        {
          id: 3,
          type: "attachment",
          idMember: "36027j1930450d8bf7b10158",
          message: "attached a link",
          time: "45 mins. ago",
        },
      ],
      due: "2017-08-29T10:16:34.000Z",
    },
    {
      id: "5637273da9b93bb84743a0f9",
      name: "Fix splash screen bugs",
      description: "",
      idAttachmentCover: "",
      idMembers: ["56027c1930450d8bf7b10758"],
      idLabels: ["5640635e19ad3a5dc21416b2"],
      attachments: [],
      subscribed: true,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "d9005a4b89ed2aadca48a6ad",
      name: "Add alternative authentication pages",
      description: "",
      idAttachmentCover: "",
      idMembers: ["36027j1930450d8bf7b10158"],
      idLabels: ["6540635g19ad3s5dc31412b2", "56027e4119ad3a5dc28b36cd"],
      attachments: [],
      subscribed: false,
      checklists: [
        {
          id: "dbfb.99bd0ad37dabc.e05046f0c824d.18f26bb524c96.78bebc8488634.240c0ee6a5e45.4cb872965",
          name: "Pages",
          checkItems: [
            {
              id: 1,
              name: "Login",
              checked: true,
            },
            {
              id: 2,
              name: "Register",
              checked: true,
            },
            {
              id: 3,
              name: "Lost Password",
              checked: false,
            },
            {
              id: 4,
              name: "Recover Password",
              checked: false,
            },
            {
              id: 5,
              name: "Activate Account",
              checked: false,
            },
          ],
        },
      ],
      activities: [],
      due: null,
    },
    {
      id: "5787b7e4740c57bf0dffd5b6",
      name: "Fix the console",
      description: "We need to fix the console asap!",
      idAttachmentCover: "",
      idMembers: [],
      idLabels: ["26022e4129ad3a5sc28b36cd"],
      attachments: [],
      subscribed: true,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message: "I'm on it!",
          time: "now",
        },
      ],
      due: "2020-09-07T12:00:00.000Z",
    },
    {
      id: "f6b9d7a9247e5d794a081927",
      name: "New media player",
      description: "",
      idAttachmentCover: "",
      idMembers: [
        "76027g1930450d8bf7b10958",
        "56027c1930450d8bf7b10758",
        "26027s1930450d8bf7b10828",
      ],
      idLabels: ["5640635e19ad3a5dc21416b2", "6540635g19ad3s5dc31412b2"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "acc6.9c673cd2f5e35.521e91d8d5991.4b2a95e0539d1.027930c0743c5.7ad1ea7bea476.e8fbe6347",
      name: "Memory Leak",
      description: "",
      idAttachmentCover: "",
      idMembers: ["36027j1930450d8bf7b10158"],
      idLabels: ["26022e4129ad3a5sc28b36cd", "5640635e19ad3a5dc21416b2"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "3279.3d69b40cc0b75.690252b6bea08.1e1789b0b7c2e.2f264b8661ce2.84d5f56910e23.429be5e8a",
      name: "Broken toolbar on profile page",
      description: "",
      idAttachmentCover: "",
      idMembers: ["26027s1930450d8bf7b10828"],
      idLabels: ["26022e4129ad3a5sc28b36cd"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message: "This should be a medium priority bug, shouldn't it?",
          time: "now",
        },
      ],
      due: null,
    },
    {
      id: "ba01.8e1a43f92a03a.0022bd5cbb9ba.275c64d911d8c.880e0846a3966.f75ff43e53ad.48ad612e7",
      name: "Button hover style",
      description:
        "If there are 3 or more buttons in certain page, weird flashing happens when you hover over the red ones.",
      idAttachmentCover: "",
      idMembers: ["26027s1930450d8bf7b10828"],
      idLabels: ["26022e4129ad3a5sc28b36cd", "5640635e19ad3a5dc21416b2"],
      attachments: [],
      subscribed: true,
      checklists: [],
      activities: [],
      due: "2017-03-08T09:00:00.000Z",
    },
    {
      id: "80ed.24ad3b18e2668.f28fbbceeeff9.5a834620a42f1.5909be19a2bf2.6c4a54947ce2d.da356b0c1",
      name: "New header designs",
      description: "",
      idAttachmentCover: "12027cafbe3b52ecf2ef632c",
      idMembers: [],
      idLabels: [
        "56027e4119ad3a5dc28b36cd",
        "6540635g19ad3s5dc31412b2",
        "5640635e19ad3a5dc21416b2",
      ],
      attachments: [
        {
          id: "12027cafbe3b52ecf2ef632c",
          name: "header-.jpg",
          src: "assets/images/scrumboard/header-1.jpg",
          time: "Added Nov 3 at 15:22AM",
          type: "image",
        },
        {
          id: "55027ced1e1a12ecf1fced2a",
          name: "header-2.jpg",
          src: "assets/images/scrumboard/header-2.jpg",
          time: "Added Nov 1 at 12:34PM",
          type: "image",
        },
      ],
      subscribed: false,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message: "Currently we have two new designs ready to ship.",
          time: "now",
        },
      ],
      due: null,
    },
    {
      id: "0ad2.7862f947bc456.f42b446df54cb.d1dd9e93601a1.9deb1406d1404.0b3c278fc7001.733341b42",
      name: "Fixed footer",
      description: "",
      idAttachmentCover: "",
      idMembers: ["26027s1930450d8bf7b10828", "56027c1930450d8bf7b10758"],
      idLabels: ["6540635g19ad3s5dc31412b2"],
      attachments: [],
      subscribed: true,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "bad3.51be8ad33acaf.9540ecb37f7e8.6bee596cfe7d3.44c68bee289c4.b96ed0b9f0af7.e14846035",
      name: "Collapsable navigation",
      description: "",
      idAttachmentCover: "",
      idMembers: [],
      idLabels: ["6540635g19ad3s5dc31412b2"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [
        {
          id: 1,
          type: "comment",
          idMember: "36027j1930450d8bf7b10158",
          message:
            "I'm not sure why we re-doing the navigation. The current collapsable navigation works flawlessly.",
          time: "now",
        },
      ],
      due: null,
    },
    {
      id: "44d1.2b51ea6cc2b5d.21f4a3412e857.8ffa2d8b44ad9.ac87215ed53a1.67d4921ad8f8d.9f318bcb2",
      name: "Mail app new layout",
      description:
        "Current layout has lots of flaws in mobile. Outlook view should help with that.",
      idAttachmentCover: "",
      idMembers: [
        "56027c1930450d8bf7b10758",
        "26027s1930450d8bf7b10828",
        "76027g1930450d8bf7b10958",
        "36027j1930450d8bf7b10158",
      ],
      idLabels: ["56027e4119ad3a5dc28b36cd", "26022e4129ad3a5sc28b36cd"],
      attachments: [],
      subscribed: false,
      checklists: [],
      activities: [],
      due: null,
    },
    {
      id: "7987.9740ba532b0d4.f9d12243f7362.507c0738dc561.87fba0a03df6e.75e6508cacf10.7a9835b54",
      name: "API recover and monitoring",
      description: "We need a service to monitor and recover failed APIs.",
      idAttachmentCover: "",
      idMembers: ["36027j1930450d8bf7b10158", "76027g1930450d8bf7b10958"],
      idLabels: ["26022e4129ad3a5sc28b36cd", "5640635e19ad3a5dc21416b2"],
      attachments: [],
      subscribed: true,
      checklists: [
        {
          id: "6926.2b31d119e4a.889401e0ca7a0.13ad8ce2e569d.976e54e8b5d87.456afccd7e820.d6c77106a",
          name: "API Monitoring",
          checkItems: [
            {
              id: 1,
              name: "Simple dashboard design",
              checked: false,
            },
            {
              id: 2,
              name: "Should be able to see different time periods on the same dashboard",
              checked: true,
            },
            {
              id: 3,
              name: "Different colors for different clusters",
              checked: true,
            },
          ],
        },
        {
          id: "7c22.5261c7924387f.248e8b1d32205.003f7a9f501d1.1d48dcdbe8b23.8099dcc5f75a7.29a966196",
          name: "API Recovery",
          checkItems: [
            {
              id: 1,
              name: "Warning notifications to all developers",
              checked: false,
            },
            {
              id: 2,
              name: "Immediate recovery options attached to the notifications",
              checked: true,
            },
            {
              id: 3,
              name: "Backups every 6hours",
              checked: false,
            },
          ],
        },
      ],
      activities: [],
      due: "2017-02-02T11:20:34.000Z",
    },
  ],
  members: [
    {
      id: "56027c1930450d8bf7b10758",
      name: "Alice Freeman",
      avatar: "assets/images/avatars/alice.jpg",
    },
    {
      id: "26027s1930450d8bf7b10828",
      name: "Danielle Obrien",
      avatar: "assets/images/avatars/danielle.jpg",
    },
    {
      id: "76027g1930450d8bf7b10958",
      name: "James Lewis",
      avatar: "assets/images/avatars/james.jpg",
    },
    {
      id: "36027j1930450d8bf7b10158",
      name: "John Doe",
      avatar: "assets/images/avatars/Velazquez.jpg",
    },
  ],
  labels: [
    {
      id: "26022e4129ad3a5sc28b36cd",
      name: "High Priority",
      class: "bg-red text-white",
    },
    {
      id: "56027e4119ad3a5dc28b36cd",
      name: "Design",
      class: "bg-orange text-white",
    },
    {
      id: "5640635e19ad3a5dc21416b2",
      name: "App",
      class: "bg-blue text-white",
    },
    {
      id: "6540635g19ad3s5dc31412b2",
      name: "Feature",
      class: "bg-green text-white",
    },
  ],
};

export const credentials = {
  id: "Lvo2SyEaXnB1XnfyQpU",
  key: "KY2RuU6mdRBTG0e67NN3BjMet4e99l6PJfKMnDvr6j2KDmbCdShMqC",
  algorithm: "sha256",
};

export const industryTypes = [
  { key: "0", value: "Select Industry Type" },
  { key: "1", value: "Agriculture, Forestry and Fisheries" },
  { key: "2", value: "Mining" },
  { key: "3", value: "Construction" },
  { key: "4", value: "Manufacturing" },
  { key: "5", value: "Wholesale Trade" },
  { key: "6", value: "Retail Trade" },
  { key: "7", value: "Transportation" },
  { key: "8", value: "Finance, Insurance and Real Estate" },
  { key: "9", value: "Services" },
  { key: "10", value: "Public Administration" },
];

export const businessTypes = {
  "Select Industry Type": ["Select Business Type"],
  "Agriculture, Forestry and Fisheries": [
    { key: "1", value: "Agricultural Production (Crops)" },
    { key: "2", value: "Agricultural Production (Livestock)" },
    { key: "3", value: "Agricultural Services" },
    { key: "4", value: "Dairy Farming" },
    { key: "5", value: "Fhishing, Hunting and Trappoing" },
    { key: "6", value: "Fishery" },
    { key: "7", value: "Forestry" },
    { key: "8", value: "Other" },
  ],
  Mining: [
    { key: "1", value: "Coal Mining" },
    { key: "2", value: "Metal Mining" },
    {
      key: "3",
      value: "Mining and Quarrying of Non-Metalic Minerals, except Fuels",
    },
    { key: "4", value: "Oil and Gas Extraction" },
    { key: "5", value: "Process Plants, Refineries and Production Plants" },
    { key: "6", value: "Other" },
  ],
  Construction: [
    {
      key: "1",
      value:
        "Building Construction, General Contractors and Operative Builders",
    },
    { key: "2", value: "Construction (Special Trade Contractors)" },
    {
      key: "3",
      value: "Heavy Construction other than Building Construction Contractors",
    },
    { key: "4", value: "Other" },
  ],
  Manufacturing: [
    {
      key: "1",
      value:
        "Apparel and other Finished Products made from Fabrics and similar materials",
    },
    { key: "2", value: "Chemicals and Allied Products" },
    {
      key: "3",
      value:
        "Electronic and other Electrical Equipment and Components, except Computer Equipment",
    },
    {
      key: "4",
      value:
        "Fabricated Metal Products, except Machinery and Transport Equipment",
    },
    { key: "5", value: "Food and Kindred Products" },
    { key: "6", value: "Furniture and Fixtures" },
    {
      key: "7",
      value: "Industrial and Commercial Machinery and Computer Equipment",
    },
    { key: "8", value: "Leather and Leather Products" },
    { key: "9", value: "Lumber and Wood Products, except Furniture" },
    {
      key: "10",
      value:
        "Measuring, Analyzing, and Controlling Instruments (Photographic, Medical and Optical Goods)",
    },
    { key: "11", value: "Miscellaneous Manufacturing Industries" },
    { key: "12", value: "Paper and Allied Products" },
    { key: "13", value: "Petroleum Refining and Related Industries" },
    { key: "14", value: "Primary Metal Industries" },
    { key: "15", value: "Printing, Publishing and Allied Industries" },
    { key: "16", value: "Rubber and Miscellaneous Plastic Products" },
    { key: "17", value: "Stone, Clay, Glass and Concrete Products" },
    { key: "18", value: "Tabacco Products" },
    { key: "19", value: "Textile Mill Products" },
    { key: "20", value: "Transportation Equipment" },
    { key: "21", value: "Other" },
  ],
  "Wholesale Trade": [
    { key: "19", value: "Wholesale (Trade-durable Goods)" },
    { key: "20", value: "Wholesale (Trade-non-durable Goods)" },
    { key: "21", value: "Other" },
  ],
  "Retail Trade": [
    { key: "1", value: "Apparel and Accessory Stores" },
    { key: "2", value: "Automotive Dealership" },
    {
      key: "3",
      value:
        "Building Materials, Hardware, Garden Supply, and Mobile Home Dealers",
    },
    { key: "4", value: "Food Stores" },
    { key: "5", value: "Gassoline/ Fuel Service Stations" },
    { key: "6", value: "General Merchandise Stores" },
    { key: "7", value: "Home Furniture, Furnishings, and Equipment Stores" },
    { key: "8", value: "Miscellaneous Retail" },
    { key: "9", value: "Restaurants, Bars and Fast Food Outlets" },
    { key: "10", value: "Other" },
  ],
  Transportation: [
    { key: "1", value: "Communications" },
    { key: "2", value: "Electric, Gas, and Sanitary Services" },
    {
      key: "3",
      value:
        "Local and Suburban Transit and Interurban Highway Passenger Transportation",
    },
    { key: "4", value: "Motor Freight Transportation and Warehousing" },
    { key: "5", value: "Pipelines, except Natural Gas" },
    { key: "6", value: "Railroad Transportation" },
    { key: "7", value: "Transportation by Air" },
    { key: "8", value: "Transportation Services" },
    { key: "9", value: "United States Postal Service" },
    { key: "10", value: "Water Transportation" },
    { key: "11", value: "Other" },
  ],
  "Finance, Insurance and Real Estate": [
    { key: "1", value: "Depository Institutions" },
    { key: "2", value: "Holding and other Investment Offices" },
    { key: "3", value: "Insurance Agents, Brokers and Services" },
    { key: "4", value: "Insurance Carriers" },
    { key: "5", value: "Non-depository Credit Institutions" },
    { key: "6", value: "Real Estate" },
    {
      key: "7",
      value: "Security and Commodity Brokers, Dealers, Exchanges, and Services",
    },
    { key: "8", value: "Other" },
  ],
  Services: [
    { key: "1", value: "Accounting Services" },
    { key: "2", value: "Amusement and Recreation Services" },
    { key: "3", value: "Automotive Repair, Services, and Parking" },
    { key: "4", value: "Botanical and Zoological Gardens" },
    { key: "5", value: "Business Services" },
    { key: "6", value: "Educational Services" },
    { key: "7", value: "Engineering Consulting" },
    { key: "8", value: "Health Services" },
    {
      key: "9",
      value: "Hotels, Rooming Houses, Camps and other Lodging Places",
    },
    { key: "10", value: "Legal Services" },
    { key: "11", value: "Management Consulting and Related Services" },
    { key: "12", value: "Membership Organizations" },
    { key: "13", value: "Miscellaneous Repair Services" },
    { key: "14", value: "Miscellaneous Services" },
    { key: "15", value: "Motion Pictures" },
    { key: "16", value: "Museums and Art Galleries" },
    { key: "17", value: "Personal Services" },
    { key: "18", value: "Research and Related Services" },
    { key: "19", value: "Social Services" },
    { key: "20", value: "Other" },
  ],
  "Public Administration": [
    { key: "1", value: "Administration of Economic Programs" },
    {
      key: "2",
      value: "Administration of Environmental Quality and Housing Programs",
    },
    { key: "3", value: "Administration of Human Resources Program" },
    {
      key: "4",
      value: "Exceutive, Legislative and General Government, except Finance",
    },
    { key: "5", value: "Justice, Public Order and Safety Activities" },
    { key: "6", value: "National Security and International Affairs" },
    { key: "7", value: "Nonclasssifiable Establishments" },
    { key: "8", value: "Public Finance, Taxation and Monetary Policy" },
    { key: "9", value: "Other" },
  ],
};

export const regions = [
  { key: "0", value: "Select One" },
  { key: "1", value: "Africa" },
  { key: "2", value: "Asia" },
  { key: "3", value: "Europe" },
  { key: "4", value: "North America" },
  { key: "5", value: "South America" },
  { key: "6", value: "Australia" },
];

export const group = [
  "Emergency Repair",
  "Calibration",
  "Rotable Repair",
  "Parts Required",
  "Rotable Replacement",
];

export const group1 = ["Mechanical", "Electrical"];

export const group2 = ["Extra Data"];

export const keywords = ["Breakdown"];

export const assetsList = [
  { code: "FOR001", description: "Forklift { FOR001 }" },
  { code: "FF150-0001", description: "FORD F150 { FF150-0001 }" },
  { code: "CNC-00001", description: "CNC { CNC-00001 }" },
  { code: "ACME001", description: "Conveyor Belt 1 { ACME001 }" },
  { code: "Engine 1", description: "Engine { Engine 1 }" },
  { code: "Engine 2", description: "Engine { Engine 2 }" },
  { code: "PNR11005", description: "Frac Pump { PNR11005 }" },
  { code: "Propetro -1", description: "PRopetro Engine 1 { Propetro -1 }" },
  {
    code: "TR001-FP001-EG001",
    description: "FRAC PUMP ENGINE 1 - OLD { TR001-FP001-EG001 }",
  },
  {
    code: "TR001-FP001-EG002",
    description: "FRAC PUMP ENGINE 2 - NEW { TR001-FP001-EG002 }",
  },
  { code: "TR001-FP001", description: "FRAC PUMP { TR001-FP001 }" },
  { code: "MOT-0002", description: "Motor { MOT-0002 }" },
  { code: null, description: "Propetro Engine " },
  { code: "Boiler-0001", description: "Boiler { Boiler-0001 }" },
  { code: "PL-0001", description: "PRODUCTION LINE { PL-0001 }" },
  { code: "TRL001", description: "Blender { TRL001 }" },
  { code: "PMP001", description: "Frac Pump { PMP001 }" },
  {
    code: "FE-ROT001",
    description: "FLUID-END SPM QWS2500HD { FE-ROT001 }   QWS2500-FE-45-SS-SPM",
  },
  { code: "PP-0001", description: "PUNCH PRESS { PP-0001 }" },
];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function convertCurrency(num) {
  if (num !== undefined) {
    return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "$0.00";
  }
}

export const taskLegendData = [
  {
    category: "subtask",
    title: "Subtask Icons",
    content: [
      {
        key: 1,
        icon: <img className="w-24" src={SubtaskNone} />,
        label: "No Subtasks Specified",
      },
      {
        key: 3,
        icon: <img className="w-24" src={SubtaskPartial} />,
        label: "Subtasks Partially Complete",
      },
      {
        key: 4,
        icon: <img className="w-24" src={SubtaskCompleted} />,
        label: "All Subtasks Complete",
      },
      {
        key: 2,
        icon: <img className="w-24" src={SubtaskNoMandatory} />,
        label: "Mandatory Subtasks Not Complete",
      },
    ],
  },
  {
    category: "labor",
    title: "Labor Icons",
    content: [
      {
        key: 1,
        icon: <img className="w-24" src={LaborNone} />,
        label: "No Labor Added To Task",
      },
      {
        key: 2,
        icon: <img className="w-24" src={LaborEst} />,
        label: "Estimated Labor Added Only",
      },
      {
        key: 3,
        icon: <img className="w-24" src={LaborComplete} />,
        label: "Estimated And Actual Labor Added",
      },
    ],
  },
  {
    category: "parts",
    title: "Parts Icons",
    content: [
      {
        key: 1,
        icon: <img className="w-24 mr-5" src={PartsNone} />,
        label: "No Parts Added To Task",
      },
      {
        key: 2,
        icon: <img className="w-24 mr-5" src={PartsEst} />,
        label: "Estimated Parts Added Only",
      },
      {
        key: 3,
        icon: <img className="w-24 mr-5" src={PartsComplete} />,
        label: "Estimated And Actual Parts Added",
      },
    ],
  },
  {
    category: "status",
    title: "Task Status Icons",
    content: [
      {
        key: false,
        icon: <img className="w-24" src={StatusPartial} />,
        label: "Not Completed",
      },
      {
        key: true,
        icon: <StatusComplete className="w-24" style={{ color: green[500] }} />,
        label: "Completed",
      },
    ],
  },
];
