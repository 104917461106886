import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "../../@fuse";
import { appsConfigs } from "../main/apps/appsConfigs";
import { AssetsConfig } from "../main/Assets/AssetsConfig";
import { MaintenanceSystemConfig } from "../main/MaintenanceSystem/MaintenanceSystemConfig";
import { PeopleConfig } from "../main/People/PeopleConfig";
import { MaintenanceAnalysisConfig } from "../main/MaintenanceAnalysis/MaintenanceAnalysisConfig";
import { EquipmentAnalysisConfig } from "../main/EquipmentAnalysis/EquipmentAnalysisConfig";
import { LoginConfig } from "../main/login/LoginConfig";
import { RedirectConfig } from "../main/Redirect/RedirectConfig";
import { SamlConsumeConfig } from "../main/SamlConsume/SamlConsumeConfig";
import { HomeConfig } from "../main/Home/homeConfig";
import { LiveMaintenanceAnalysisConfig } from "../main/LiveMaintenanceAnalysis/LiveMaintenanceAnalysisConfig";
import { LiveAppsConfigs } from "../main/Live/LiveAppsConfigs";
import { UATAppsConfigs } from "../main/UAT/UATAppsConfigs";
import { MaintenancePlansConfig } from "../main/MaintenancePlans/MaintenancePlansConfig";
import { DOTComplianceConfig } from "../main/DOTCompliance/DOTComplianceConfig";
import { F3ReportingConfig } from "../main/F3Reporting/F3ReportingConfig";

const routeConfigs = [
  ...appsConfigs,
  AssetsConfig,
  MaintenanceSystemConfig,
  PeopleConfig,
  MaintenanceAnalysisConfig,
  EquipmentAnalysisConfig,
  LoginConfig,
  RedirectConfig,
  SamlConsumeConfig,
  HomeConfig,
  LiveMaintenanceAnalysisConfig,
  ...LiveAppsConfigs,
  ...UATAppsConfigs,
  MaintenancePlansConfig,
  DOTComplianceConfig,
  F3ReportingConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ["admin"]),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/home" />,
  },
  {
    component: () => <Redirect to="/pages/errors/error-404" />,
  },
];

export default routes;
