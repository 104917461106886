
import { PersonnelAppConfig } from "./Personnel/PersonnelAppConfig";
import { UserSettingsConfig } from "./UserSettings/UserSettingsConfig";
import { RedirectConfig } from "./Redirect/RedirectConfig";
import { SamlConsumeConfig } from "./SamlConsume/SamlConsumeConfig";
import { CrewsConfig } from "./crews/CrewsConfig";
import { WorkRequestsConfig } from "./WorkRequest/WorkRequestConfig";
import { ScrumboardAppConfig } from "./scrumboard/ScrumboardAppConfig";

export const LiveAppsConfigs = [
  PersonnelAppConfig,
  UserSettingsConfig,
  RedirectConfig,
    SamlConsumeConfig,
    CrewsConfig,
    WorkRequestsConfig,
    ScrumboardAppConfig
];
