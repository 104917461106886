/*
 * Navigation Authorization Roles
 */

const navAuthProfiles = {
    superAdmin: ['Super Administrator'],
    readOnly: ['Read/ View Only'],
    admin: ['Super Administrator', 'Administrator'],
    moveManager: ['Super Administrator', 'Administrator', 'Move Manager', 'Read/ View Only'],
    manager: ['Super Administrator', 'Administrator', 'Manager', 'Move Manager', 'Read/ View Only'],
    assetManager: ['Super Administrator', 'Administrator', 'Manager', 'Asset Maintenance', 'Move Manager'],
    technician: ['Super Administrator', 'Administrator', 'Technician', 'Manager', 'Move Manager', 'Read/ View Only'],
    analyticsOnly: ['Super Administrator', 'Administrator', 'Technician', 'Manager', 'Move Manager', 'Read/ View Only', 'Reporting Access'],
};

export default navAuthProfiles;