import React from "react";

export const FileManagerAppConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  routes: [
    {
      path: "/asset-management/document-management",
      component: React.lazy(() => import("./FileManagerApp"))
    }
  ]
};
