import React from "react";

export const MaintenanceSystemConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        toolbar: {
          display: true
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  routes: [
    {
      path: "/asset-management/maintenance-system",
      component: React.lazy(() => import("./MaintenanceSystem"))
    }
  ]
};
