import React from "react";

export const LiveMaintenanceAnalysisConfig = {
  settings: {
    layout: {
          config: {
              rightSidePanel: {
                  display: false
              }
          }
    }
  },
  routes: [
    {
          path: "/analytics/maintenance-analysis-live",
      component: React.lazy(() => import("./LiveMaintenanceAnalysis"))
    }
  ]
};
