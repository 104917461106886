import React from 'react';
import {Redirect} from 'react-router-dom';

export const UserSettingsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path: '/settings/users/',
            component: React.lazy(() => import('./UserSettings'))
        }
    ]
};