import React from "react";
import { AppBar, Hidden, Icon } from "@material-ui/core";
import { FuseScrollbars } from "../../../../@fuse";
import clsx from "clsx";
import UserNavbarHeader from "../../../../app/fuse-layouts/shared-components/UserNavbarHeader";
//import Logo from "app/fuse-layouts/shared-components/Logo";
import NavbarFoldedToggleButton from "../../../../app/fuse-layouts/shared-components/NavbarFoldedToggleButton";
import NavbarMobileToggleButton from "../../../../app/fuse-layouts/shared-components/NavbarMobileToggleButton";
import Navigation from "../../../../app/fuse-layouts/shared-components/Navigation";
import { makeStyles, useTheme } from "@material-ui/styles";

//const theme = useTheme();
const useStyles = makeStyles( theme => ({
  content: {
    overflowX: "hidden",
    overflowY: "auto",
    "-webkit-overflow-scrolling": "touch",
    background:
      "linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 40px, 100% 10px",
    backgroundAttachment: "local, scroll"
   },
    header: {
        backgroundColor: theme.palette.primary[300]
    }
}));

function NavbarLayout1(props) {
  const classes = useStyles();

  return (
    <div
      className={clsx("flex flex-col overflow-hidden h-full", props.className)}
    >
          <AppBar
              className={clsx("flex flex-row items-center justify-center flex-shrink h-64 min-h-64 pr-3", classes.header)}
              position="static"
              elevation={3}
              style={{ paddingLeft: '1.2rem' }}
        >
              {/*<div className="flex flex-1" /> */}

            <Hidden mdDown>
                <div className="justify-center w-full">
                    <NavbarFoldedToggleButton className="w-40 h-40 p-3" />
                </div>
            </Hidden>

            <Hidden lgUp>
                <NavbarMobileToggleButton className="w-40 h-40 p-3">
                    <Icon>arrow_back</Icon>
                </NavbarMobileToggleButton>
            </Hidden>
        </AppBar>

      <FuseScrollbars
        className={clsx(classes.content)}
        option={{ suppressScrollX: true }}
      >
        <UserNavbarHeader />

        <Navigation layout="vertical" />
      </FuseScrollbars>
    </div>
  );
}

export default NavbarLayout1;
