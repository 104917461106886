import React from 'react';
import {Redirect} from 'react-router-dom';

export const PersonnelAppConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path: '/maintenance-management/personnel/:id',
            component: React.lazy(() => import('./PersonnelApp'))
        },
        {
            path: '/maintenance-management/personnel',
            component: () => <Redirect to="/maintenance-management/personnel/all"/>
        }
    ]
};